<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="6">
        <v-form ref="form">
          <v-text-field
            v-model="formData.firstName"
            :counter="50"
            :label="$t('userRegistration.firstName')"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.lastName"
            :counter="50"
            :label="$t('userRegistration.lastName')"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.email"
            :label="$t('userRegistration.email')"
            :counter="150"
            required
          ></v-text-field>

          <v-text-field
            v-model="formData.phone"
            :label="$t('userRegistration.phone')"
            :counter="60"
          ></v-text-field>

          <v-select
            v-model="formData.role"
            :items="roles"
            :label="$t('userRegistration.selectRole')"
            required
          ></v-select>
          <v-autocomplete
            v-if="clients"
            :items="selectItems"
            v-model="selectedClients"
            :label="$t('userRegistration.selectCompany')"
            deletable-chips
            small-chips
            multiple
          ></v-autocomplete>
          <v-autocomplete
            v-if="isAdmin"
            v-model="selectedPipelines"
            :items="selectPipelines"
            :label="$t('userRegistration.selectPipelines')"
            deletable-chips
            multiple
            small-chips
          ></v-autocomplete>
          <v-btn color="success" class="mr-4" @click="register">
            {{ $t('userRegistration.register') }}
          </v-btn>
          <v-btn color="error" @click="reset"
            >{{ $t('userRegistration.resetForm') }}
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserService from './../services/UserService'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      roles: [
        {
          text: 'Admin',
          value: 'admin',
        },
        {
          text: 'Account',
          value: 'account',
        },
        {
          text: 'Client',
          value: 'client',
        },
      ],
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
      },
      selectedPipelines: [],
      selectedClients: [],
      selectItems: [],
      errorMsg: null,
    }
  },
  created() {
    this.$store.dispatch('client/getClients')
  },
  methods: {
    selectClient(selected) {
      this.formData.permissions.push({ clientId: selected.value })
    },
    reset() {
      this.$refs.form.reset()
      this.selectedPipelines = []
      this.selectedClients = []
    },
    register() {
      let payload = this.formData
      payload.pipelines = this.selectedPipelines.map((p) => ({
        pipelineId: p,
      }))
      payload.permissions = this.selectedClients.map((c) => ({
        clientId: c,
      }))
      UserService.registerUser(payload)
        .then(() => {
          this.$store.dispatch('snackbar/addSnackbar', {
            text: `${this.formData.email} ${this.$i18n.t(
              'snackbar.registeredSuccessfully'
            )}.`,
            color: 'success',
          })
          this.reset()
        })
        .catch((error) => {
          this.errorMsg = error.response.status
          this.$store.dispatch('snackbar/addSnackbar', {
            text: `${this.$i18n.t('snackbar.couldNotRegisterUser')}`,
            color: 'error',
          })
        })
    },
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
      pipelines: (state) => state.pipeline.pipelines,
    }),
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    selectPipelines() {
      return this.pipelines.map((p) => ({
        text: this.$i18n.t(`sideBarLinks['${p.name}']`),
        value: p.id,
      }))
    },
  },
  watch: {
    clients() {
      this.selectItems = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
  },
}

// "admin"
// 	UserRoleAccount UserRole = "account"
// 	UserRoleClient  UserRole = "client"
</script>

<style lang="scss" scoped></style>
